/**
 * Example Stub for creating WCMC Subthemes
 *
 */

// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

/* =Site-specific styles
----------------------------------------------------------*/

//======Reference ===================
$cornell-red: $wcm-red;
$blue: #003558;
$navigation-blue: #001A2C;
$text: rgb(106,106,106);//#767f91#696969 - dark blue
$secondary-nav:#DFDFDF;//#EFF6FE#BCBFC0#CECECE - light gray
$tertiary-nav:#F2F3F3;//#EAEAEA - lighter gray - footer

//====Custom Site Colors=============
//Change these first and see if the result makes sense with your design
$primary-color: $cornell-red;
$secondary-color: $blue;
$tertiary-color: $navigation-blue;
$quadrary-color: $secondary-nav; //Horizontal rules, borders. Section separators
$base-color: $wcm-white; //Background of page mostly
$text-color: $text;
$link-color: $cornell-red; //Duh
$button-color: $cornell-red; //As stated, unless overridden by other styles
$social-button-color: $cornell-red; //Obvious

//If once you've changed the above colors things still don't look right, customize these values
$heading-colors: $primary-color;
$desktop-site-name-color: $base-color;
$mobile-site-name-color: $secondary-color;
$secondary-content-header-color: $secondary-color; //Heading in sidebars
$header-borders-color: $wcm-white;
$third-level-nav-background-color: $secondary-nav;//$base-color
$third-level-nav-inactive-color: $tertiary-color;
$body-nav-background-color: $tertiary-color;
$primary-nav-background-color: $navigation-blue; //Background of main nav bar
$secondary-nav-highlight-color: $blue; //Active primary/secondary nav
$mobile-wcmc-text: $text-color; //WCMC text at top of page in mobile

//Images and logo colors
//Tablet dropdown logo
$header-wcmc-logo-color: "white"; // QUOTES ARE IMPORTANT- red, black, or white
//Desktop circle emblem
$header-wcmc-emblem-color: "white"; //QUOTES ARE IMPORTANT- red, black, or white

//===========Measurements based on content=============
$primary-nav-width: 20%; //Based on 4 primary nav items- change to your liking



@import "_navigation";
@import "_header";
@import "_footer";
@import "_homepage";
@import "_internal_pages";
