
.front{

  /*BODY*/
  #main {
    max-width: 960px;
    margin: 0 auto;
  }

  .moscone-sidebar {
    width: 80%;
    float: none;
    margin: 0 auto;
    margin-top: -60px;
    @include breakpoint($md) {
      width: 34.043%;
      float: left;
      margin-top: -40px;
    }
  }

  .moscone-content {
    width: 100%;
    float: none;
    margin: 0 auto;
    @include breakpoint($lg) {
      width: 80%;
    }
    @include breakpoint($md) {
      width: 65.957%;
      float: left;
    }
  }

  #main-content a[href^="http"]:after {
    display: none;
  }

  #main-content h3{
    &:first-of-type{
      margin-top:0px;
    }
  }

  /*CONTENT STYLE*/
  .arrow{
    display: inline-block;
    vertical-align: baseline;
    line-height: 0em;
    margin-top: 0px;
    font-size: 3.4em;
    padding-left: 0em;
        &:hover{
        text-decoration: none;
        }
    }

  .mission-statement {
    font-size: 1.5em !important;
    font-weight: 500;
    font-family: $wcm-regular;
    text-align: center;
    padding: 30px 5px;
    line-height: 1.5em;
    color: $wcm-med-gray;
    .mission-statement_link:after{
      display: none;
    }
    @include breakpoint($lg) {
      font-size: 3.3em;
      text-align: center;
      width: 90%;
      margin:0 auto;
      line-height: 1.1em;
      a {
        font-size: 1em;
        color: $wcm-dark-orange;
        }
      a:hover{
          color: $wcm-bright-orange; 
        }
     }
  }

  .title-long {
    line-height: 2.5rem;
  }

  .intro-links {
    width: 95%;
    text-align: center;
    h3 {
      text-align: center;
      text-transform: capitalize;
      text-transform: initial;//for ie8
      border-top:0px;
      padding: 5px 0px;
      font-weight: 600;
    }
  }

  .homepage_icons{
    padding: 40px 10px 10px 10px;
  }


  .intro {
    a, a:hover {
      text-decoration: none;
    }

    h3 {
        margin-top: 0px;
        margin-bottom: -14px;
        background-repeat: no-repeat;
        text-decoration: none;
        }
      p { // cheating here to get two lines high, while i figure how to give a character count in cms
        text-align: center;
        height: 60px;
        color: $text-color;
        text-decoration: none;
      }
    }

  .labs, .research, .policy {
  h3{
    font-weight: 400;
    }
  }

  .date {
    margin-top: -16px;
    color: $blue;
    font-size: 1em;
    font-family: sans-serif;
    border-bottom: 1px solid rgb(211, 213, 216);
    margin-bottom: 20px;
    }

  .coverage-link {
    margin-top: -16px;
    }

  .item-list {
    border-top: 1px solid #dfdfdf;
    margin: 25px 0;
    padding-top: 10px;
    font-size: 2em;
    line-height: 30px;
  }

  .item-list .pager {
    padding: 10px 0;
    font-size: 1.7em;
    margin: 10px 0;
    font-family: serif;
    li{
      font-weight: 100;
      padding: 0.2em;
      margin: 0px 0px 0.25em 1em;
      }
    }

    .view-carousel .cycle-slideshow .carousel-slide .slide-text h2 {
      @include breakpoint($md) {
        text-align: left;
        padding-top: 15px;
      }
    }

  }

