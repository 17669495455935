/* =============================================================================
   ~Components
   ========================================================================== */


/*EXPOSED FORM*/
.views-exposed-form {
  margin-bottom: 30px;
  height: auto;
  min-height: 100px;
  border: 1px solid;
  padding: 10px 20px;
  text-align: left;
  .views-exposed-widget{
    padding: 0em 0em 0em .9em;
    }
  .form-submit {
    margin-top: 1.2em;
    }
}

.select2-container{
  width: 265px !important;
  .select2-selection--single {
    height: 40px;
    border: 1px solid $wcm-border-gray;

    .select2-selection__rendered {
    line-height: 40px;
    }
  }
}

html.js input.form-autocomplete {
  background-position: 100% 6px;
  height: 25px;
}



/*
.panel-hero-3col {
   #main-content {
    min-height: 20em;
    padding: 1.5em 6% 10% 0%;
    @include breakpoint($md) {
      border-right: 1px solid $secondary-nav;
    }
}
*/

/*
#related-content-sidebar{
  margin: 3% 0px 50px 40px;
}
*/

/*
.information-column{
  padding-left: 4%;
  padding-top: 3%;
  width:100%;
  @include breakpoint($lg){
        width:27%;
  }
  @include breakpoint($md){
        width:27%;
  }
}
*/
/*
#information-sidebar{
  // width: 100% !important;
  margin: 0 auto;
  h3{
    margin-bottom: 0px;
  }
  .site-name {
    font-weight: 800;
    }
  }
}
*/

/*
#main-content a[href^="http"]:after,
.main-content p a[href^="http"]:after{
  padding-left: 10px;
}
*/

/*PUBLICAITONS*/
#biblio-header {
    display: block;
    height: 50px;

    ul {
        float: left;
        width: 100%;
        height: 20px;
        list-style: none;
        padding-left: 0px;

        li {
            float: left;
            padding-right: 20px;

            a {
                color: #777;
            }
        }
    }

    .biblio-export {
        text-align: right;
        text-decoration: none;
        float: right;
    }

    .biblio-alpha-line {
        text-align: center;
    }
}

.biblio-separator-bar {
    color: #000000;
    font-weight: bold;
    background-color: #e1e1e1;
    border: 1px solid #ccc;
    padding: 0.5em;
    margin: 1em 0 1em 0;
}

.biblio-entry {
    margin: 1em 0 1em 0;

    .biblio-title {
        font-weight: bold;
        text-decoration: none;
        font-style: normal;
        line-height: normal;
        text-align: left;
        font-family: "Open Sans",sans-serif;
        color: #cf4520;
    }

    ul.biblio-export-buttons, ul.biblio-export-buttons li {
        background: transparent;
        list-style-image: none;
        list-style-type: none;
        display: inline;
        border-bottom: 0px;
        border-right: 1px;
        padding: 0;
        margin: 0.1em;
        li {
            background: transparent;
            list-style-image: none;
            list-style-type: none;
            display: inline;
            border-bottom: 0px;
            border-right: 1px;
            padding: 0;
            margin: 0.1em;
        }
    }
}

/*VIEWS ROWS*/
  .views-row{
    padding: 5px 0px;
    border-bottom: solid 1px $wcm-border-gray;
  }

  .views-row-last{
    border-bottom: solid 1px $wcm-white;
  }
