/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/*LEVEL 1 DRAWER NAV OVERRIDE*/
@media screen and (min-width: 768px) {
  #primary-nav .level-1 {
    width: 20%; } }

@media screen and (min-width: 992px) {
  #primary-nav .level-1 {
    width: 20%; } }

@media screen and (min-width: 1200px) {
  #primary-nav .level-1 {
    width: 20%; } }

#drawer-nav .level-2 {
  width: 33%;
  padding: 8px 30px 8px 0px; }

/*FOOTER NAV*/
.block-menu-block .content {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

/*LOGO*/
.brand__lockup img {
  height: 84px; }

.front {
  /*BODY*/
  /*CONTENT STYLE*/ }
  .front #main {
    max-width: 960px;
    margin: 0 auto; }
  .front .moscone-sidebar {
    width: 80%;
    float: none;
    margin: 0 auto;
    margin-top: -60px; }
    @media screen and (min-width: 992px) {
      .front .moscone-sidebar {
        width: 34.043%;
        float: left;
        margin-top: -40px; } }
  .front .moscone-content {
    width: 100%;
    float: none;
    margin: 0 auto; }
    @media screen and (min-width: 1200px) {
      .front .moscone-content {
        width: 80%; } }
    @media screen and (min-width: 992px) {
      .front .moscone-content {
        width: 65.957%;
        float: left; } }
  .front #main-content a[href^="http"]:after {
    display: none; }
  .front #main-content h3:first-of-type {
    margin-top: 0px; }
  .front .arrow {
    display: inline-block;
    vertical-align: baseline;
    line-height: 0em;
    margin-top: 0px;
    font-size: 3.4em;
    padding-left: 0em; }
    .front .arrow:hover {
      text-decoration: none; }
  .front .mission-statement {
    font-size: 1.5em !important;
    font-weight: 500;
    font-family: "1898Sans-Regular", sans-serif;
    text-align: center;
    padding: 30px 5px;
    line-height: 1.5em;
    color: #666666; }
    .front .mission-statement .mission-statement_link:after {
      display: none; }
    @media screen and (min-width: 1200px) {
      .front .mission-statement {
        font-size: 3.3em;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        line-height: 1.1em; }
        .front .mission-statement a {
          font-size: 1em;
          color: #cf4520; }
        .front .mission-statement a:hover {
          color: #e7751d; } }
  .front .title-long {
    line-height: 2.5rem; }
  .front .intro-links {
    width: 95%;
    text-align: center; }
    .front .intro-links h3 {
      text-align: center;
      text-transform: capitalize;
      text-transform: initial;
      border-top: 0px;
      padding: 5px 0px;
      font-weight: 600; }
  .front .homepage_icons {
    padding: 40px 10px 10px 10px; }
  .front .intro a, .front .intro a:hover {
    text-decoration: none; }
  .front .intro h3 {
    margin-top: 0px;
    margin-bottom: -14px;
    background-repeat: no-repeat;
    text-decoration: none; }
  .front .intro p {
    text-align: center;
    height: 60px;
    color: #6a6a6a;
    text-decoration: none; }
  .front .labs h3, .front .research h3, .front .policy h3 {
    font-weight: 400; }
  .front .date {
    margin-top: -16px;
    color: #003558;
    font-size: 1em;
    font-family: sans-serif;
    border-bottom: 1px solid #d3d5d8;
    margin-bottom: 20px; }
  .front .coverage-link {
    margin-top: -16px; }
  .front .item-list {
    border-top: 1px solid #dfdfdf;
    margin: 25px 0;
    padding-top: 10px;
    font-size: 2em;
    line-height: 30px; }
  .front .item-list .pager {
    padding: 10px 0;
    font-size: 1.7em;
    margin: 10px 0;
    font-family: serif; }
    .front .item-list .pager li {
      font-weight: 100;
      padding: 0.2em;
      margin: 0px 0px 0.25em 1em; }
  @media screen and (min-width: 992px) {
    .front .view-carousel .cycle-slideshow .carousel-slide .slide-text h2 {
      text-align: left;
      padding-top: 15px; } }

/* =============================================================================
   ~Components
   ========================================================================== */
/*EXPOSED FORM*/
.views-exposed-form {
  margin-bottom: 30px;
  height: auto;
  min-height: 100px;
  border: 1px solid;
  padding: 10px 20px;
  text-align: left; }
  .views-exposed-form .views-exposed-widget {
    padding: 0em 0em 0em .9em; }
  .views-exposed-form .form-submit {
    margin-top: 1.2em; }

.select2-container {
  width: 265px !important; }
  .select2-container .select2-selection--single {
    height: 40px;
    border: 1px solid #dddddd; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      line-height: 40px; }

html.js input.form-autocomplete {
  background-position: 100% 6px;
  height: 25px; }

/*
.panel-hero-3col {
   #main-content {
    min-height: 20em;
    padding: 1.5em 6% 10% 0%;
    @include breakpoint($md) {
      border-right: 1px solid $secondary-nav;
    }
}
*/
/*
#related-content-sidebar{
  margin: 3% 0px 50px 40px;
}
*/
/*
.information-column{
  padding-left: 4%;
  padding-top: 3%;
  width:100%;
  @include breakpoint($lg){
        width:27%;
  }
  @include breakpoint($md){
        width:27%;
  }
}
*/
/*
#information-sidebar{
  // width: 100% !important;
  margin: 0 auto;
  h3{
    margin-bottom: 0px;
  }
  .site-name {
    font-weight: 800;
    }
  }
}
*/
/*
#main-content a[href^="http"]:after,
.main-content p a[href^="http"]:after{
  padding-left: 10px;
}
*/
/*PUBLICAITONS*/
#biblio-header {
  display: block;
  height: 50px; }
  #biblio-header ul {
    float: left;
    width: 100%;
    height: 20px;
    list-style: none;
    padding-left: 0px; }
    #biblio-header ul li {
      float: left;
      padding-right: 20px; }
      #biblio-header ul li a {
        color: #777; }
  #biblio-header .biblio-export {
    text-align: right;
    text-decoration: none;
    float: right; }
  #biblio-header .biblio-alpha-line {
    text-align: center; }

.biblio-separator-bar {
  color: #000000;
  font-weight: bold;
  background-color: #e1e1e1;
  border: 1px solid #ccc;
  padding: 0.5em;
  margin: 1em 0 1em 0; }

.biblio-entry {
  margin: 1em 0 1em 0; }
  .biblio-entry .biblio-title {
    font-weight: bold;
    text-decoration: none;
    font-style: normal;
    line-height: normal;
    text-align: left;
    font-family: "Open Sans",sans-serif;
    color: #cf4520; }
  .biblio-entry ul.biblio-export-buttons, .biblio-entry ul.biblio-export-buttons li {
    background: transparent;
    list-style-image: none;
    list-style-type: none;
    display: inline;
    border-bottom: 0px;
    border-right: 1px;
    padding: 0;
    margin: 0.1em; }
    .biblio-entry ul.biblio-export-buttons li, .biblio-entry ul.biblio-export-buttons li li {
      background: transparent;
      list-style-image: none;
      list-style-type: none;
      display: inline;
      border-bottom: 0px;
      border-right: 1px;
      padding: 0;
      margin: 0.1em; }

/*VIEWS ROWS*/
.views-row {
  padding: 5px 0px;
  border-bottom: solid 1px #dddddd; }

.views-row-last {
  border-bottom: solid 1px #fff; }
